<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'TimeTable',
  page: {
    title: 'Time Table',
    meta: [
      {
        name: 'description',
        content: 'Automated Organizing Time Table of Class',
      },
    ],
  },
}
</script>
